import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { ProductBlock } from "./components";
import { products } from "../../data";

export const ShopScene = () => {
  useEffect(() => {
    document.title = "Moose and Goose books | Shop";
  }, []);
  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Shop</h1>
          <h2 className="splash-subtitle">Online</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Shop</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            {products
              .filter((product) => product.active)
              .map((product, i) => (
                <ProductBlock key={i} {...product} />
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ShopScene;
