import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";

import Stockists from "../HomeScene/components/Stockists";

export const StockistsScene = () => {
  useEffect(() => {
    document.title = "Moose and Goose books | About";
  }, []);

  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Our</h1>
          <h2 className="splash-subtitle">Stockists</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Stockists />
        </Container>
      </div>
    </>
  );
};

export default StockistsScene;
