import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import GA from "../../utils/GoogleAnalytics";

import styles from "./colouring-scene.module.scss";

import { ReactComponent as Colouring1 } from "../../assets/colouring-01.svg";
import { ReactComponent as Colouring2 } from "../../assets/colouring-02.svg";
import { ReactComponent as Colouring3 } from "../../assets/colouring-03.svg";

const cx = classNames.bind(styles);

export const ColouringScene = ({ ...rest }: any) => {
  const svgRef = useRef<any>(null);
  const linkRef = useRef<any>(null);
  const [currentSvg, setCurrentSvg] = useState<string>("Colouring1");
  const [colour, setColour] = useState<string>("Colouring1");
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    document.title = "Moose and Goose books | Colouring";
  }, []);

  const colours = [
    "#2E2210",
    "#000102",
    "#0E2436",
    "#E1D5C4",
    "#6E4F22",
    "#C5925C",
    "#FFAB34",
    "#707B2A",
    "#4A97C9",
    "#7C0807",
    "#008470",
    "#F6ACA5",
    "#51C9C5",
    "#828282",
    "#FF8600",
    "#FE8127",
    "#CFAE00",
    "#E4C500",
    "#6F455E",
    "#3D2E39",
    "#ffc500",
    "#E30000",
  ];

  const handleSave = () => {
    var svg = svgRef.current;
    var svgData = new XMLSerializer().serializeToString(svg);

    var canvas = document.createElement("canvas");
    var ctx: any = canvas.getContext("2d");
    if (ctx) {
      ctx.canvas.width = 900;
      ctx.canvas.height = 900;

      var img = document.createElement("img");
      img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));

      img.onload = function () {
        ctx.drawImage(img, 0, 0, 900, 900);

        setImageSrc(canvas.toDataURL("image/png"));
        linkRef.current.click();
      };
    }
    GA.trackEvent("colouring", "buttons", "Download", currentSvg);
  };

  return (
    <>
      <div className="splash-small level-0">
        <Container>
          <h1 className="splash-title">Colouring</h1>
          <h2 className="splash-subtitle">Fun</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content page-content--tight">
        <Container>
          <Row>
            <Col sm={12} md={9}>
              <div className={styles.container}>
                {currentSvg === "Colouring1" && (
                  <Colouring1
                    id={"svgid"}
                    className={styles.svg}
                    ref={svgRef}
                    onClick={(event: any) => {
                      console.log(event.target.getAttribute("fill"));
                      if (
                        event &&
                        event.target &&
                        event.target?.style &&
                        event.target.getAttribute("fill")
                      ) {
                        event.target.style.fill = colour;
                      }
                    }}
                  />
                )}
                {currentSvg === "Colouring2" && (
                  <Colouring2
                    id={"svgid"}
                    className={styles.svg}
                    ref={svgRef}
                    onClick={(event: any) => {
                      if (
                        event &&
                        event.target &&
                        event.target?.style &&
                        event.target.getAttribute("fill")
                      ) {
                        event.target.style.fill = colour;
                      }
                    }}
                  />
                )}
                {currentSvg === "Colouring3" && (
                  <Colouring3
                    id={"svgid"}
                    className={styles.svg}
                    ref={svgRef}
                    onClick={(event: any) => {
                      if (
                        event &&
                        event.target &&
                        event.target?.style &&
                        event.target.getAttribute("fill")
                      ) {
                        event.target.style.fill = colour;
                      }
                    }}
                  />
                )}
              </div>
            </Col>
            <Col sm={12} md={3}>
              <h2 className="h4 mt-3 mb-3">Choose a picture</h2>
              <Form.Group>
                <Form.Control
                  as="select"
                  size="lg"
                  onChange={(event) => {
                    setCurrentSvg(event?.target?.value);
                  }}
                >
                  <option value="Colouring1">Picture 1</option>
                  <option value="Colouring2">Picture 2</option>
                  <option value="Colouring3">Picture 3</option>
                </Form.Control>
              </Form.Group>

              <h2 className="h4 mb-3">Pick a colour</h2>
              <ul className={styles.colourList}>
                {colours.map((hex) => (
                  <li
                    key={hex}
                    className={cx(
                      styles.colourSwatch,
                      hex === colour ? styles.activeSwatch : null
                    )}
                    style={{ background: hex }}
                    aria-label={hex}
                    role="button"
                    onClick={() => {
                      setColour(hex);
                    }}
                  ></li>
                ))}
              </ul>
              <Button
                block
                variant="success"
                className="mt-3"
                onClick={handleSave}
              >
                Download your pic
              </Button>
            </Col>
          </Row>
          <a
            style={{ opacity: 0 }}
            href={imageSrc}
            download={currentSvg}
            ref={linkRef}
          >
            DOWNLOAD
          </a>
        </Container>
      </div>
    </>
  );
};

export default ColouringScene;
