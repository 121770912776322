import React, { useRef, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export const AudioScene = () => {
  const audioRef = useRef<any>(null);

  useEffect(() => {
    document.title = "Audio book";
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Img variant="top" src="/audiobanner.png" />
            <Card.Body>
              <audio controls ref={audioRef} className="audio-element">
                <source src="/audio/5370329776.mp3" type="audio/mpeg" />
              </audio>
              <Card.Title>Moose & Goose go to the Moon</Card.Title>
              <Card.Text>
                <p>
                  Welcome to the onine audio book of Moose &amp; Goose go to the
                  Moon.
                </p>
                <hr />
                <p className="mb-0">Narrated by</p>
                <p>
                  <strong>Catherine Bradley</strong>
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AudioScene;
