export interface IColour {
  code: string;
  hex: string;
  hex2?: string;
  title: string;
  img?: string;
}

export interface ISize {
  code: string;
  title: string;
}

export interface IType {
  code: string;
  title: string;
}

export interface IVariations {
  colours?: IColour[];
  sizes?: ISize[];
  types?: IType[];
}

export interface IProduct {
  active: boolean;
  available: boolean;
  description: string;
  displayPrice: number;
  img1: string;
  img2: string;
  productId: string | number;
  skuRef: string;
  subtitle: string;
  title: string;
  variations?: IVariations;
  weight: number;
}

const products: IProduct[] = [
  {
    active: true,
    available: false,
    description:
      "Moose and Goose invite you to join them on an amazing journey of bravery, friendship and a little bit of luck.\n\nAll books are lovingly made to order, so once we receive yours, we’ll get your book processed and shipped out to you.",
    displayPrice: 6.99,
    img1: "/img/products/book.png",
    img2: "/img/products/book.png",
    productId: 1,
    skuRef: "MAGMOON",
    subtitle: "First edition",
    title: "Moose & Goose go to the Moon",
    weight: 350,
    variations: {
      types: [{ code: "SB", title: "Softback" }],
    },
  },
  {
    active: true,
    available: true,
    description:
      "- Reduced Chance Of Pilling - the Belcoro yarn enhances the t-shirt's durability. \n\n - Smooth Surface - there are fewer loose and broken fibres used within the Belcoro yarn compared to standard yarn which makes it a suitable surface for print and embroidery\n\n - Crew neck with cotton/Lycra® rib",
    displayPrice: 12,
    img1: "/img/products/french-navy.png",
    img2: "/img/products/french-navy.png",
    productId: 2,
    skuRef: "STTK909",
    subtitle: "Multiple colours",
    title: "Moose & Goose T-shirt",
    weight: 100,
    variations: {
      colours: [
        {
          code: "FNY",
          hex: "rgb(44, 42, 57)",
          title: "French Navy",
          img: "/img/products/french-navy.png",
        },
        {
          code: "ANT",
          hex: "#5d5d5d",
          title: "Anthracite",
          img: "/img/products/anthracite.png",
        },
        {
          code: "CRD",
          hex: "rgb(226, 48, 69)",
          title: "Carmine Red",
          img: "/img/products/carmin-red.png",
        },
        {
          code: "RBL",
          hex: "rgb(45, 113, 165)",
          title: "Royal Blue",
          img: "/img/products/royal-blue.png",
        },
        {
          code: "MJB",
          hex: "rgb(17, 89, 155)",
          title: "Majorelle Blue",
          img: "/img/products/majorelle.png",
        },
        {
          code: "ODP",
          hex: "rgb(42, 97, 114)",
          title: "Ocean Depth",
          img: "/img/products/ocean-depth.png",
        },
        {
          code: "RAS",
          hex: "rgb(241, 59, 102)",
          title: "Raspberry",
          img: "/img/products/raspberry.png",
        },
        {
          code: "TNG",
          hex: "rgb(232, 76, 49)",
          title: "Tangerine",
          img: "/img/products/tangerine.png",
        },
      ],
      sizes: [
        { code: "3-4", title: "3-4 years" },
        { code: "5-6", title: "5-6 years" },
        { code: "7-8", title: "7-8 years" },
        { code: "9-11", title: "9-11 years" },
        { code: "12-14", title: "12-14 years" },
      ],
    },
  },
  {
    active: true,
    available: true,
    description:
      'A classic ceramic mug to enjoy your favourite drink from. Tea? Coffee? We don’t mind. Just so long as you enjoy it!\nWhen you’ve finished, pop in the dishwasher. No worries!\n\nThe fun bit...\n11oz glossy ceramic.\nBright white glossy finish with a coloured handle and inner.\nLine from the book "The​ ​rocket​ ​set​ ​off​ ​​with a WHIZ and a ZOOM"\nDishwasher & Microwave safe.',
    displayPrice: 6.99,
    img1: "/img/products/MUG-CER-W-CO2.png",
    img2: "/img/products/MUG-CER-W-CO1.png",
    productId: 3,
    skuRef: "MUG-CER",
    subtitle: "Multiple colours",
    title: "Rocket Mug",
    weight: 400,
    variations: {
      colours: [
        {
          code: "W/CO",
          hex: "rgb(35, 27, 86)",
          hex2: "rgb(255, 255, 255)",
          title: "Cobalt Blue",
          img: "/img/products/MUG-CER-W-CO2.png",
        },
        {
          code: "W/CB",
          hex: "rgb(94, 144, 205)",
          hex2: "rgb(255, 255, 255)",
          title: "Cambridge Blue",
          img: "/img/products/MUG-CER-W-CB2.png",
        },
        {
          code: "W/SY",
          hex: "rgb(236, 170, 58)",
          hex2: "rgb(255, 255, 255)",
          title: "Yellow",
          img: "/img/products/MUG-CER-W-SY2.png",
        },
        {
          code: "WH/BK",
          hex: "rgb(0, 0, 0)",
          hex2: "rgb(255, 255, 255)",
          title: "Black",
          img: "/img/products/MUG-CER-WH-BK2.png",
        },
        {
          code: "WH/RD",
          hex: "rgb(236, 0, 11)",
          hex2: "rgb(255, 255, 255)",
          title: "Bright Red",
          img: "/img/products/MUG-CER-WH-RD2.png",
        },
      ],
    },
  },
  {
    active: true,
    available: true,
    description:
      "Rock the “rocket” look with our embroidered cotton baseball cap.\n\nOne size fits all\n100% cotton\nAvailable in 7 colours",
    displayPrice: 14.99,
    img1: "/img/products/BB15-FNA.png",
    img2: "/img/products/BB15-CRE.png",
    productId: 4,
    skuRef: "BB15",
    subtitle: "Multiple colours",
    title: "Embroidered Rocket Cap",
    weight: 200,
    variations: {
      colours: [
        {
          code: "FNA",
          hex: "rgb(44, 42, 57)",
          title: "French Navy",
          img: "/img/products//BB15-FNA.png",
        },
        {
          code: "BLK",
          hex: "#000000",
          title: "Black",
          img: "/img/products/BB15-BLK.png",
        },
        {
          code: "WHT",
          hex: "rgb(255, 255, 255)",
          title: "White",
          img: "/img/products/BB15-WHI.png",
        },
        {
          code: "CRE",
          hex: "rgb(186, 21, 44)",
          title: "Classic Red",
          img: "/img/products/BB15-CRE.png",
        },
        {
          code: "GRA",
          hex: "rgb(94, 94, 94)",
          title: "Grey",
          img: "/img/products/BB15-GRA.png",
        },
        {
          code: "BRO",
          hex: "rgb(30, 68, 141)",
          title: "Bright Royal",
          img: "/img/products/BB15-BRO.png",
        },
        {
          code: "BUR",
          hex: "rgb(96, 36, 50)",
          title: "Burgundy",
          img: "/img/products/BB15-BUR.png",
        },
      ],
    },
  },
];

export { products };
