import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import book from "../../banners/book.png";
import emailbanner from "../../banners/emailbanner.png";
import banner4 from "../../banners/banner4.jpg";
import GA from "../../utils/GoogleAnalytics";
import audiobookmock from "../../assets/base/audiobook2.png";
import rakutenkobo from "../../assets/stockists/rakutenkobo.png";
import bookmate from "../../assets/stockists/bookmate.png";
import bookbeat from "../../assets/stockists/bookbeat.png";
import youscribe from "../../assets/stockists/youscribe.png";
import audiobookscom from "../../assets/stockists/audiobookscom.png";
import libro from "../../assets/stockists/libro.png";
import itunes from "../../assets/stockists/itunes.png";
import audible from "../../assets/stockists/audible.png";

import { StarFill } from "react-bootstrap-icons";

declare const window: any;

export const HomeScene = () => {
  const audioRef = useRef<any>(null);
  let ml_webform_3229618 = window.ml_webform_3229618;
  let myfunc = window.myFunc;

  useEffect(() => {
    myfunc();
    document.title = "Moose and Goose Books | Home";
  }, [myfunc]);

  return (
    <>
      <div className="splash-screen large level-0">
        <Container>
          <h1 className="splash-title">Let's go</h1>
          <h2 className="splash-subtitle">to the Moon</h2>
        </Container>
        <div className="splash-moose"></div>
        <div className="splash-moon"></div>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Row className="mb-5">
            <Col sm={4}>
              <Link to={"/shop/1"}>
                <img
                  alt="Moose and Goose go to the Moon"
                  className="img-fluid"
                  src="/img/products/book1.jpg"
                />
              </Link>
            </Col>
            <Col sm={8}>
              <span className="badge badge-info">FIRST EDITION</span>
              <h2 className="h1 title-themed">
                Moose &amp; Goose go to the Moon
              </h2>
              <p>
                Moose and Goose invite you to join them on an amazing journey of
                bravery, friendship and a little bit of luck.
              </p>
              <p>
                All books are lovingly made to order, so once we receive yours,
                we’ll get your book processed and shipped out to you.
              </p>
              <Button
                variant="success"
                href="/shop/1"
                size="lg"
                className="btn-xs-block"
                onClick={() => {
                  GA.trackEvent("homeshop", "buttons", "Get your copy", "1");
                }}
              >
                Get your copy
              </Button>
            </Col>
          </Row>
          <Card className="mb-5">
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col md={8}>
                    <span className="badge badge-info">OUT NOW</span>
                    <h2 className="h1 title-themed">Audiobook</h2>
                    <p>
                      Now you can listen to the adventures of Moose and Goose as
                      they blast off to the Moon in their brand new audiobook
                      narrated by Catherine Bradley.
                    </p>
                    <h3 className="h3 title-themed mb-4">Try it now:</h3>
                    <audio
                      controls
                      ref={audioRef}
                      className="audio-element mb-4"
                      style={{ width: "100%" }}
                    >
                      <source src="/audio/preview.mp3" type="audio/mpeg" />
                    </audio>
                    <h3 className="h3 title-themed">Available from:</h3>
                    <Row>
                      <Col xs={4}>
                        <a
                          href="https://books.apple.com/gb/audiobook/moose-goose-go-to-the-moon-unabridged/id1561978522"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={itunes}
                            alt="iTunes"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://www.audible.co.uk/search?keywords=moose+and+goose+go+to+the+moon&ref=a_hp_t1_header_search"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={audible}
                            alt="iTunes"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://www.kobo.com/gb/en/audiobook/moose-goose-go-to-the-moon"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={rakutenkobo}
                            alt="Rakuten Kobo"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://bookmate.com/audiobooks/ABa35uMV?dscvr=top_result"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={bookmate}
                            alt="Bookmate"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://www.bookbeat.co.uk/book/moose-goose-go-moon-325519"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={bookbeat}
                            alt="Bookbeat"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://en.youscribe.com/search?quick_search=Moose+%26+Goose"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={youscribe}
                            alt="Youscribe"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://www.audiobooks.co.uk/audiobook/moose-and-goose-go-to-the-moon/509349"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={audiobookscom}
                            alt="Audiobookscom"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col xs={4}>
                        <a
                          href="https://libro.fm/audiobooks/9781662180484-moose-goose-go-to-the-moon"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={libro}
                            alt="libro.fm"
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4} className="d-none d-md-block">
                    <Link to={"/shop/1"}>
                      <img
                        alt="Moose and Goose go to the Moon"
                        className="img-fluid"
                        src={audiobookmock}
                      />
                    </Link>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
          <Row className="text-center mb-3">
            <Col>
              <h2 className="h1 title-themed">More from Moose &amp; Goose</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={{ order: 2, span: 12 }} sm={12} md={6} lg={4} xl={4}>
              <Card className="shadow">
                <Card.Body className="text-center">
                  <img
                    className="card-img-top mb-4"
                    alt="Newsletter"
                    src={emailbanner}
                    onClick={() => {
                      ml_webform_3229618("show");
                      GA.trackEvent(
                        "homefeature",
                        "buttons",
                        "Keep updated",
                        "image"
                      );
                    }}
                  />

                  <h4>Keep updated</h4>
                  <p className="lead">
                    Stay up to date with Moose &amp; Goose and their adventures.
                  </p>
                  <Button
                    onClick={() => {
                      ml_webform_3229618("show");
                      GA.trackEvent(
                        "homefeature",
                        "buttons",
                        "Keep updated",
                        "button"
                      );
                    }}
                    variant="outline-dark"
                  >
                    Keep updated
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={{ order: 3, span: 12 }} sm={12} md={6} lg={4} xl={4}>
              <Card className="shadow">
                <Card.Body className="text-center">
                  <Link
                    to="/funstuff"
                    onClick={() => {
                      GA.trackEvent(
                        "homefeature",
                        "buttons",
                        "FREE Downlaods",
                        "image"
                      );
                    }}
                  >
                    <img
                      className="card-img-top mb-4"
                      alt="Fun stuff"
                      src={banner4}
                    />
                  </Link>

                  <h4>Fun stuff</h4>
                  <p className="lead">
                    Some fun FREE Moose &amp; Goose activities.
                  </p>
                  <Button
                    variant="outline-dark"
                    href="/funstuff"
                    onClick={() => {
                      GA.trackEvent(
                        "homefeature",
                        "buttons",
                        "FREE Downlaods",
                        "button"
                      );
                    }}
                  >
                    FREE Downloads
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={{ order: 1, span: 12 }} sm={12} md={6} lg={4} xl={4}>
              <Card className="shadow">
                <Card.Body className="text-center">
                  <Link
                    to="/blog"
                    onClick={() => {
                      GA.trackEvent(
                        "homefeature",
                        "buttons",
                        "Get the latest",
                        "image"
                      );
                    }}
                  >
                    <img
                      className="card-img-top mb-4"
                      alt="Pre order Moose and Goose"
                      src={book}
                    />
                  </Link>

                  <h4>Get the latest</h4>
                  <p className="lead">
                    See what's going on behind the scenes at Moose &amp; Goose.
                  </p>
                  <Button
                    variant="outline-dark"
                    href="/blog"
                    onClick={() => {
                      GA.trackEvent(
                        "homefeature",
                        "buttons",
                        "Get the latest",
                        "button"
                      );
                    }}
                  >
                    Read more
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5 mb-3 text-center">
            <Col>
              <span className="badge badge-info">REVIEWS</span>
              <h2 className="h1 title-themed">What our customers say</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Card className="mb-2">
                <Card.Body>
                  <div className="mb-3">
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                  </div>
                  <h5>Sue Barker</h5>
                  <p>
                    This is an excellent book two good friends got together and
                    made exciting adventures happen .Love the book !
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card className="mb-2">
                <Card.Body>
                  <div className="mb-3">
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                  </div>
                  <h5>Fiona Grimer</h5>
                  <p>
                    My daughter and I LOVE reading this book together. It’s
                    become a firm family favourite at bedtime and she loves
                    listening to Moose and Goose’s adventures in space. The
                    fabulous illustrations really capture her imagination too.
                    We have really enjoyed all the website resources - like the
                    character matching game and colouring in pages. What a
                    brilliant team Moose and Goose are! We can’t wait to read
                    the latest instalment of their next adventures!
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card className="mb-2">
                <Card.Body>
                  <div className="mb-3">
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                    <StarFill size={30} color="#ffc500" />
                  </div>
                  <h5>Rebecca</h5>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Row className="mt-4">
          <Col className="text-center">
            <div
              className="embedsocial-collect"
              data-ref="8537acdfc57144f3a494"
            ></div>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col className="text-center pt-5 pb-5">
              <a
                className="footer-link"
                href="mailto: contact@mooseandgoosebooks.co.uk;"
              >
                <i className="far fa-envelope fa-3x"></i>
              </a>
              <a
                className="footer-link"
                href="https://www.instagram.com/mooseandgoosebooks/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram fa-3x"></i>
              </a>
              <a
                className="footer-link"
                href="https://www.facebook.com/mooseandgoosebooks"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook fa-3x"></i>
              </a>
              <a
                className="footer-link"
                href="https://twitter.com/moosegoosebooks"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter fa-3x"></i>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HomeScene;
