import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import Container from "react-bootstrap/Container";
import HomeScene from "./scenes/HomeScene";
import ActivitiesScene from "./scenes/ActivitiesScene";
import ColouringScene from "./scenes/ColouringScene";
import AudioScene from "./scenes/AudioScene";
import AboutScene from "./scenes/AboutScene";
import BasketScene from "./scenes/BasketScene";
import ShopScene from "./scenes/ShopScene";
import CheckoutScene from "./scenes/CheckoutScene";
import ShopProductScene from "./scenes/ShopScene/ShopProductScene";
import BlogScene from "./scenes/BlogScene";
import BlogPostScene from "./scenes/BlogScene/BlogPostScene";
import ResourcesScene from "./scenes/ResourcesScene";
import MatchingScene from "./scenes/MatchingScene";
import PhonicsScene from "./scenes/PhonicsScene";
import StockistsScene from "./scenes/StockistsScene";

import Header from "./components/Header";
import GA from "./utils/GoogleAnalytics";
import {
  itemsSelector,
  getBasketItems,
  totalQtySelector,
} from "./store/basket";

interface IProps {
  getBasketItems: () => void;
  items: any;
  total: number;
}

export const App = ({ getBasketItems, items, total }: IProps) => {
  useEffect(() => {
    getBasketItems();
  }, [getBasketItems]);
  return (
    <>
      <Container fluid className="level-1">
        <Header items={items} total={total} />
      </Container>

      <Switch>
        <Route path="/5370329776">
          <AudioScene />
        </Route>
        <Route path="/about">
          <AboutScene />
        </Route>
        <Route path="/resources">
          <ResourcesScene />
        </Route>
        <Route path="/colouring">
          <ColouringScene />
        </Route>
        <Route path="/matching">
          <MatchingScene />
        </Route>
        <Route path="/phonics">
          <PhonicsScene />
        </Route>
        <Route path="/shop/:productId">
          {({ match }) => <ShopProductScene match={match} />}
        </Route>
        <Route path="/shop">
          <ShopScene />
        </Route>
        <Route path="/blog/:postId">
          {({ match }) => <BlogPostScene match={match} />}
        </Route>
        <Route path="/blog">
          <BlogScene />
        </Route>
        <Route path="/basket">
          <BasketScene />
        </Route>
        <Route path="/confirm">
          <CheckoutScene />
        </Route>
        <Route path="/funstuff">
          <ActivitiesScene />
        </Route>
        <Route path="/stockists">
          <StockistsScene />
        </Route>
        <Route path="/">
          <HomeScene />
        </Route>
      </Switch>
      {GA.init() && <GA.RouteTracker />}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  items: itemsSelector(state),
  total: totalQtySelector(state),
});

export default connect(mapStateToProps, { getBasketItems })(App);
