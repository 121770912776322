import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import matchingbanner from "../../banners/matchingbanner.png";
import banner4 from "../../banners/banner4.jpg";
import GA from "../../utils/GoogleAnalytics";

export const ActivitiesScene = () => {
  useEffect(() => {
    document.title = "Moose and Goose books | Activities";
  }, []);

  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Fun</h1>
          <h2 className="splash-subtitle">Stuff</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Row>
            <Col xs={{ span: 12 }} sm={12} md={6} lg={4} xl={4}>
              <Card className="shadow">
                <Card.Body className="text-center">
                  <Link
                    to="/colouring"
                    onClick={() => {
                      GA.trackEvent(
                        "funstuff",
                        "buttons",
                        "colouring",
                        "button"
                      );
                    }}
                  >
                    <img
                      className="card-img-top mb-4"
                      alt="Newsletter"
                      src={banner4}
                    />
                  </Link>

                  <h4>Colouring fun</h4>
                  <p className="lead">
                    Colour your favourite Moose &amp; Goose characters.
                  </p>
                  <Button
                    href="/colouring"
                    onClick={() => {
                      GA.trackEvent(
                        "funstuff",
                        "buttons",
                        "colouring",
                        "button"
                      );
                    }}
                    variant="outline-dark"
                  >
                    Start colouring
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={{ span: 12 }} sm={12} md={6} lg={4} xl={4}>
              <Card className="shadow">
                <Card.Body className="text-center">
                  <Link
                    to="/matching"
                    onClick={() => {
                      GA.trackEvent("funstuff", "buttons", "Matching", "image");
                    }}
                  >
                    <img
                      className="card-img-top mb-4"
                      alt="Fun stuff"
                      src={matchingbanner}
                    />
                  </Link>

                  <h4>Matching fun</h4>
                  <p className="lead">
                    A fun space matching game to test you memory.
                  </p>
                  <Button
                    variant="outline-dark"
                    href="/matching"
                    onClick={() => {
                      GA.trackEvent(
                        "funstuff",
                        "buttons",
                        "Matching",
                        "button"
                      );
                    }}
                  >
                    Play now
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={{ span: 12 }} sm={12} md={6} lg={4} xl={4}>
              <Card className="shadow">
                <Card.Body className="text-center">
                  <Link
                    to="/resources"
                    onClick={() => {
                      GA.trackEvent(
                        "funstuff",
                        "buttons",
                        "Downloads",
                        "image"
                      );
                    }}
                  >
                    <img
                      className="card-img-top mb-4"
                      alt="Pre order Moose and Goose"
                      src={banner4}
                    />
                  </Link>

                  <h4>Printable fun</h4>
                  <p className="lead">
                    Printable Moose &amp; Goose activity sheets to keep you
                    entertained.
                  </p>
                  <Button
                    variant="outline-dark"
                    href="/resources"
                    onClick={() => {
                      GA.trackEvent(
                        "funstuff",
                        "buttons",
                        "Downloads",
                        "button"
                      );
                    }}
                  >
                    Show me
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ActivitiesScene;
