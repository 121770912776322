import React from "react";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

interface IProps {
  blurb: string;
  banner: string;
  createdAt: number;
  headline: string;
  postId: string;
}

export const PostBlock = ({
  blurb,
  banner,
  createdAt,
  headline,
  postId,
}: IProps) => {
  return (
    <Col sm={12} md={6} lg={4} xl={4} className="mt-4">
      <Card className="shadow">
        <Card.Body className="text-center">
          <a href={`/blog/${postId}`}>
            <img className="card-img-top" alt={blurb} src={banner} />
          </a>
          <ul className="list-inline mt-3">
            <li className="list-inline-item">
              <i className="fas fa-user"></i> Craig
            </li>
            <li className="list-inline-item">
              <i className="far fa-clock"></i>{" "}
              {moment(createdAt).format("D-M-yyyy")}
            </li>
          </ul>
          <hr />
          <div className="lead">{headline}</div>
          {blurb}
          <a
            className="btn btn-outline-dark my-2"
            href={`/blog/${postId}`}
            role="button"
          >
            Read more...
          </a>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default PostBlock;
