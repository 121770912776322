import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import GA from "../../utils/GoogleAnalytics";

export const ResourcesScene = () => {
  useEffect(() => {
    document.title = "Moose and Goose books | Downloads";
  }, []);
  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Free</h1>
          <h2 className="splash-subtitle">Downloads</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Row>
            <Col sm={12} md={4} lg={4} xl={4}>
              <Card className="shadow mb-3">
                <Card.Body className="text-center">
                  <a href="/downloads/colouring1.pdf" target="_blank">
                    <img
                      className="card-img-top mb-4"
                      alt="Meet Moose & Goose"
                      src={"/img/colouring1_preview.jpg"}
                    />
                  </a>

                  <h4>Meet Moose &amp; Goose</h4>
                  <Button
                    variant="outline-dark"
                    href="/downloads/colouring1.pdf"
                    target="_blank"
                    onClick={() => {
                      GA.trackEvent(
                        "download",
                        "buttons",
                        "FREE Download",
                        "colouring1"
                      );
                    }}
                  >
                    FREE Download
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} md={4} lg={4} xl={4}>
              <Card className="shadow mb-3">
                <Card.Body className="text-center">
                  <a href="/downloads/colouring2.pdf" target="_blank">
                    <img
                      className="card-img-top mb-4"
                      alt="Moose in space"
                      src={"/img/colouring2_preview.jpg"}
                    />
                  </a>

                  <h4>Moose in space</h4>
                  <Button
                    variant="outline-dark"
                    href="/downloads/colouring2.pdf"
                    target="_blank"
                    onClick={() => {
                      GA.trackEvent(
                        "download",
                        "buttons",
                        "FREE Download",
                        "colouring2"
                      );
                    }}
                  >
                    FREE Download
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <Card className="shadow mb-3">
                <Card.Body className="text-center">
                  <a href="/downloads/colouring3.pdf" target="_blank">
                    <img
                      className="card-img-top mb-4"
                      alt="No place like Zome"
                      src={"/img/colouring3_preview.jpg"}
                    />
                  </a>

                  <h4>No place like Zome</h4>
                  <Button
                    variant="outline-dark"
                    href="/downloads/colouring3.pdf"
                    target="_blank"
                    onClick={() => {
                      GA.trackEvent(
                        "download",
                        "buttons",
                        "FREE Download",
                        "colouring3"
                      );
                    }}
                  >
                    FREE Download
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <Card className="shadow mb-3">
                <Card.Body className="text-center">
                  <a href="/downloads/wordsearch1.pdf" target="_blank">
                    <img
                      className="card-img-top mb-4"
                      alt="Wordsearch"
                      src={"/img/wordsearch1_preview.jpg"}
                    />
                  </a>

                  <h4>Wordsearch</h4>
                  <Button
                    variant="outline-dark"
                    href="/downloads/wordsearch1.pdf"
                    target="_blank"
                    onClick={() => {
                      GA.trackEvent(
                        "download",
                        "buttons",
                        "FREE Download",
                        "wordsearch1"
                      );
                    }}
                  >
                    FREE Download
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResourcesScene;
