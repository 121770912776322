import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import nlbr from "react-nl2br";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { addBasketItem, itemsSelector } from "../../../store/basket";
import GA from "../../../utils/GoogleAnalytics";
import { Helmet } from "react-helmet";
import { products, IColour, IProduct } from "../../../data";
import { Formik } from "formik";
import * as yup from "yup";

interface IProps {
  addBasketItem: any;
  items: any;
  match?: any;
}

export const ShopProductScene = ({ addBasketItem, items, match }: IProps) => {
  const [currentProd, setCurrentProductState] = useState<IProduct | null>(null);
  const [currentColour, setCurrentColour] = useState<
    IColour | null | undefined
  >(null);
  let history = useHistory();

  useEffect(() => {
    const newProducts = products.filter(
      (product) =>
        product.productId.toString() === match.params.productId.toString()
    );
    const newProduct: IProduct = newProducts[0];

    if (newProduct) {
      setCurrentProductState(newProduct);
    }
  }, [match]);

  const handleFormikSubmit = (values: any) => {
    addBasketItem(values, () => {
      history.push("/basket");
    });
  };

  // const skuRef = currentProd?.skuRef || null;

  const firstTypeCode = currentProd?.variations?.types
    ? currentProd?.variations?.types[0].code
    : null;

  const firstColourCode = currentProd?.variations?.colours
    ? currentProd?.variations?.colours[0].code
    : null;

  const firstSizeCode = currentProd?.variations?.sizes
    ? currentProd?.variations?.sizes[0].code
    : null;

  // const codeParts = [skuRef, firstTypeCode, firstColourCode, firstSizeCode];

  // const initialSku = codeParts.filter(Boolean).join("-");

  const yupScehema: any = {
    qty: yup.string().required("Quantity is required"),
  };
  if (firstColourCode) {
    yupScehema["colour"] = yup.string().required("Colour is required");
  }

  if (firstSizeCode) {
    yupScehema["size"] = yup.string().required("Size is required");
  }

  if (firstTypeCode) {
    yupScehema["type"] = yup.string().required("Type is required");
  }

  const schema = yup.object(yupScehema);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Moose and Goose books | Product</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="splash-minimal level-0"></div>
      <div className="page-content">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/shop">
              Shop
            </Breadcrumb.Item>
            {currentProd && (
              <Breadcrumb.Item active>{currentProd.title}</Breadcrumb.Item>
            )}
          </Breadcrumb>
          {currentProd && (
            <Row>
              <Col sm={12} md={5}>
                {currentColour && currentColour.img && (
                  <img
                    src={currentColour.img}
                    alt={currentProd.title}
                    className="img-fluid mb-3 rounded mx-auto d-block"
                  />
                )}
                {!currentColour?.img && (
                  <img
                    src={currentProd.img1}
                    alt={currentProd.title}
                    className="img-fluid mb-3 rounded mx-auto d-block"
                  />
                )}
              </Col>
              <Col sm={12} md={7}>
                <h1 className="mb-0">{currentProd.title}</h1>
                <h2 className="mb-2 text-info">{currentProd.subtitle}</h2>
                <h3 className="text-black mb-3">
                  &pound;{currentProd.displayPrice}
                </h3>
                <p>{nlbr(currentProd.description)}</p>

                <Row>
                  <Col md={12}>
                    <Formik
                      novalidate
                      validationSchema={schema}
                      onSubmit={handleFormikSubmit}
                      initialValues={{
                        skuRef: currentProd.skuRef,
                        colour: firstColourCode,
                        type: "",
                        size: "",
                        qty: "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <Form.Group
                            as={Row}
                            controlId="colour"
                            defaultValue={firstColourCode}
                          >
                            {touched.colour && errors.colour ? (
                              <div className="error-message">
                                {errors.colour}
                              </div>
                            ) : null}
                            {currentProd.variations?.colours &&
                              currentProd.variations.colours.map(
                                (colour, i) => (
                                  <span
                                    key={`colour${i}`}
                                    className="custom-radio-wrapper col-6"
                                  >
                                    <Form.Check
                                      checked={values.colour === colour.code}
                                      isInvalid={
                                        touched.colour && !!errors.colour
                                      }
                                      custom
                                      bsCustomPrefix={"custom-colour"}
                                      name="colour"
                                      type={"radio"}
                                      id={colour.code}
                                      value={colour.code}
                                      label={colour.title}
                                      /* Set onChange to handleChange */
                                      onChange={(event) => {
                                        const selectedColour = currentProd
                                          ?.variations?.colours
                                          ? currentProd?.variations?.colours
                                              .filter(
                                                (colour) =>
                                                  colour.code ===
                                                  event.currentTarget.value
                                              )
                                              .slice(0, 1)
                                              .shift()
                                          : null;
                                        console.log(selectedColour);
                                        setCurrentColour(selectedColour);
                                        handleChange(event);
                                      }}
                                      /* Set onBlur to handleBlur */
                                      onBlur={handleBlur}
                                      style={{ background: colour.hex }}
                                    />
                                    <label htmlFor={colour.code}>
                                      {colour.title}
                                    </label>
                                  </span>
                                )
                              )}
                          </Form.Group>

                          {currentProd.variations?.types && (
                            <Row>
                              <Form.Group
                                controlId="productForm.types"
                                as={Col}
                                sm={12}
                                lg={6}
                              >
                                <Form.Label>Type</Form.Label>
                                {touched.type && errors.type ? (
                                  <div className="error-message">
                                    {errors.type}
                                  </div>
                                ) : null}
                                <Form.Control
                                  isInvalid={touched.type && !!errors.type}
                                  name="type"
                                  as="select"
                                  /* Set onChange to handleChange */
                                  onChange={handleChange}
                                  /* Set onBlur to handleBlur */
                                  onBlur={handleBlur}
                                >
                                  <option key={`type-0`} value={""}>
                                    Select type
                                  </option>
                                  {currentProd.variations.types.map(
                                    (type, i) => (
                                      <option
                                        key={`type-${i}`}
                                        value={type.code}
                                      >
                                        {type.title}
                                      </option>
                                    )
                                  )}
                                </Form.Control>
                              </Form.Group>
                            </Row>
                          )}

                          {currentProd.variations?.sizes && (
                            <Row>
                              <Form.Group
                                controlId="productForm.sizes"
                                as={Col}
                                sm={12}
                                lg={6}
                              >
                                <Form.Label>Size</Form.Label>
                                {touched.size && errors.size ? (
                                  <div className="error-message">
                                    {errors.size}
                                  </div>
                                ) : null}
                                <Form.Control
                                  isInvalid={touched.size && !!errors.size}
                                  name="size"
                                  as="select"
                                  /* Set onChange to handleChange */
                                  onChange={handleChange}
                                  /* Set onBlur to handleBlur */
                                  onBlur={handleBlur}
                                >
                                  <option key={`size-0`} value={""}>
                                    Select size
                                  </option>
                                  {currentProd.variations.sizes.map(
                                    (size, i) => (
                                      <option
                                        key={`size-${i}`}
                                        value={size.code}
                                      >
                                        {size.title}
                                      </option>
                                    )
                                  )}
                                </Form.Control>
                              </Form.Group>
                            </Row>
                          )}

                          <Row>
                            <Form.Group
                              as={Col}
                              sm={12}
                              lg={6}
                              controlId="productForm.qty"
                            >
                              <Form.Label>Quantity</Form.Label>
                              {touched.qty && errors.qty ? (
                                <div className="error-message">
                                  {errors.qty}
                                </div>
                              ) : null}
                              <Form.Control
                                isInvalid={touched.qty && !!errors.qty}
                                name="qty"
                                as="select"
                                /* Set onChange to handleChange */
                                onChange={handleChange}
                                /* Set onBlur to handleBlur */
                                onBlur={handleBlur}
                              >
                                <option key={`qty-0`} value={""}>
                                  Select quantity
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </Form.Control>
                            </Form.Group>
                          </Row>

                          <Row>
                            <Col sm={12} lg={6}>
                              {!currentProd.available && (
                                <Button
                                  variant="success"
                                  size="lg"
                                  block
                                  type="submit"
                                  onClick={() => {
                                    GA.trackEvent(
                                      "productpage",
                                      "buttons",
                                      "Add to basket",
                                      currentProd.title
                                    );
                                  }}
                                >
                                  Order now
                                </Button>
                              )}
                              {currentProd.available && (
                                <Button
                                  variant="success"
                                  size="lg"
                                  block
                                  type="submit"
                                >
                                  Add to basket
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({ items: itemsSelector(state) });

export default connect(mapStateToProps, { addBasketItem })(ShopProductScene);
