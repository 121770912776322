import { createSlice } from "@reduxjs/toolkit";
import { products } from "../../data";

export const initialState = {
  details: null,
  items: {},
  total: 0,
  totalQty: 0,
  totalWeight: 0,
  itemsError: null,
  itemsIsBusy: false,
};

// slice
export const basketsSlice = createSlice({
  initialState,
  name: "basket",
  reducers: {
    addItemBusy: (state, action) => {
      state.itemsIsBusy = action.payload;
    },
    addItemError: (state, action) => {
      state.itemsError = action.payload;
      state.items = null;
    },
    listItemSuccess: (state, action) => {
      state.items = action.payload;
      state.itemsError = null;
    },
    listTotalSuccess: (state, action) => {
      state.total = action.payload;
    },
    listTotalQtySuccess: (state, action) => {
      state.totalQty = action.payload;
    },
    listTotalWeightSuccess: (state, action) => {
      state.totalWeight = action.payload;
    },
    clearBasketItems: (state, action) => {
      state.items = {};
      state.total = 0;
      state.totalQty = 0;
    },
    resetBasket: () => initialState,
    setOrderDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const {
  addItemBusy,
  addItemError,
  clearBasketItems,
  listItemSuccess,
  listTotalSuccess,
  listTotalQtySuccess,
  listTotalWeightSuccess,
  resetBasket,
  setOrderDetails,
} = basketsSlice.actions;

// thunks
export const getBasketItems = () => (dispatch) => {
  const storedBasketItems = JSON.parse(sessionStorage.getItem("basket"));

  if (storedBasketItems) {
    const enhancedBasketItems = Object.values(storedBasketItems).reduce(
      (prev, item) => {
        const matchedProducts = products.filter(
          (product) => product.skuRef === item.skuRef
        );

        const matchedProduct = matchedProducts[0];

        const matchedColour = matchedProduct.variations?.colours?.find(
          (colour) => {
            return colour.code === item.colour;
          }
        );

        const matchedSize = matchedProduct.variations?.sizes?.find((size) => {
          return size.code === item.size;
        });

        const matchedType = matchedProduct.variations?.types?.find((type) => {
          return type.code === item.type;
        });

        const newItems = {};
        newItems[item.sku] = {
          ...item,
          product: matchedProduct.title,
          productId: matchedProduct.productId,
          displayPrice: matchedProduct.displayPrice,
          total: matchedProduct.displayPrice * item.qty,
          img: matchedProduct.img1,
          colour: matchedColour,
          size: matchedSize,
          type: matchedType,
          weight: matchedProduct.weight * item.qty,
        };

        return { ...prev, ...newItems };
      },
      {}
    );

    const basketTotals = Object.values(enhancedBasketItems).reduce(
      (prev, item) => {
        return {
          totalPrice: prev.totalPrice + item.total,
          totalQty: prev.totalQty + parseInt(item.qty),
          totalWeight: prev.totalWeight + item.weight,
        };
      },
      { totalPrice: 0, totalQty: 0, totalWeight: 0 }
    );
    dispatch(listTotalSuccess(basketTotals.totalPrice));
    dispatch(listTotalQtySuccess(basketTotals.totalQty));
    dispatch(listTotalWeightSuccess(basketTotals.totalWeight));
    dispatch(listItemSuccess(enhancedBasketItems));
  }
};

export const addBasketItem = (item, success) => (dispatch, getState) => {
  const storedBasketItems = JSON.parse(sessionStorage.getItem("basket"));

  dispatch(addItemBusy(true));

  let existingSkuItems = {};
  existingSkuItems = { ...storedBasketItems };

  const codeParts = [item.skuRef, item.type, item.colour, item.size];
  const sku = codeParts.filter(Boolean).join("-");

  const matchedItem = existingSkuItems[sku];

  if (matchedItem) {
    existingSkuItems[sku] = {
      sku,
      ...item,
      qty: parseInt(matchedItem.qty) + parseInt(item.qty),
    };
    sessionStorage.setItem("basket", JSON.stringify(existingSkuItems));
  } else {
    const skuItem = {};
    skuItem[sku] = {
      sku: sku,
      ...item,
      qty: parseInt(item.qty),
    };
    const combinedItems = { ...existingSkuItems, ...skuItem };
    sessionStorage.setItem("basket", JSON.stringify(combinedItems));
  }

  dispatch(addItemBusy(false));
  dispatch(getBasketItems());
  if (success) {
    success();
  }
};

export const removeBasketItem = (sku) => (dispatch, getState) => {
  dispatch(addItemBusy(true));
  let storedBasketItems = JSON.parse(sessionStorage.getItem("basket"));
  storedBasketItems[sku] = undefined;
  sessionStorage.setItem("basket", JSON.stringify(storedBasketItems));
  dispatch(addItemBusy(false));
  dispatch(getBasketItems());
};

export const updateBasketItems = (newItems) => (dispatch, getState) => {
  dispatch(addItemBusy(true));
  let storedBasketItems = JSON.parse(sessionStorage.getItem("basket"));

  const reducedItems = Object.values(storedBasketItems).reduce(
    (prev, currentItem) => {
      if (newItems[currentItem.sku]) {
        prev[currentItem.sku] = {
          ...currentItem,
          qty: newItems[currentItem.sku],
        };
      }
      return prev;
    },
    {}
  );

  sessionStorage.setItem("basket", JSON.stringify(reducedItems));
  dispatch(addItemBusy(false));
  dispatch(getBasketItems());
};

export const completeBasket = (details) => (dispatch) => {
  sessionStorage.removeItem("basket");
  dispatch(clearBasketItems());
  dispatch(setOrderDetails(details));
};

// selectors
export const itemsIsBusySelector = (state) => state.basket.itemsIsBusy;
export const itemsErrorSelector = (state) => state.basket.itemsError;
export const itemsHasErrorSelector = (state) =>
  state.basket.itemsError ? true : false;
export const itemsSelector = (state) => state.basket.items;
export const totalSelector = (state) => state.basket.total;
export const totalQtySelector = (state) => state.basket.totalQty;
export const totalWeightSelector = (state) => state.basket.totalWeight;

export default basketsSlice.reducer;
