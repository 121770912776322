import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export const AboutScene = () => {
  useEffect(() => {
    document.title = "Moose and Goose books | About";
  }, []);

  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Meet the</h1>
          <h2 className="splash-subtitle">Characters</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Row>
            <Col sm={12} md={5}>
              <img
                src="/img/characters/moose.jpg"
                className="img-fluid mb-5"
                alt="Meet Moose"
              />
            </Col>
            <Col sm={12} md={7}>
              <div className="pt-md-4">
                <h2 className="mb-3 mt-md-5">Oh hi folks. My name is Moose.</h2>
                <p>
                  I’m big, friendly and a little bit clumsy. I love to adventure
                  with my old pal, Goose. We like to roam around, talking and
                  learning about all the great things we find. Countryside
                  walks, laughter and kindness are really important to me.
                  Always treat others how you would like to be treated yourself.
                  That’s what I say!
                </p>

                <p>
                  Anyway, enough from me...I hope you’re enjoying the website.
                  If you haven’t done so yet, order our debut book, “Moose and
                  Goose go to the Moon”.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={5}>
              <img
                src="/img/characters/goose.jpg"
                className="img-fluid mb-5"
                alt="Meet Goose"
              />
            </Col>
            <Col sm={12} md={7}>
              <div className="pt-md-4">
                <h2 className="mb-3 mt-md-5">
                  Hey hey hey! Welcome to our website.
                </h2>
                <p>
                  You have met my friend Moose... now a little bit about me. I’m
                  Goose. I am a relaxed little Goose. I rarely get in a flap. I
                  enjoy my friendships and I like to learn. Counting and words
                  are what I like the most. Hopefully during our time, we can
                  all learn together! Go and get a copy of our new book and
                  start your journey with us today!
                </p>
                <p>Thanks for stopping by; your little friend Goose.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={5}>
              <img
                src="/img/characters/cat.jpg"
                className="img-fluid mb-5"
                alt="Meet Cat"
              />
            </Col>
            <Col sm={12} md={7}>
              <div className="pt-md-4">
                <h2 className="mb-3 mt-md-5">Meow... I’m Cat.</h2>
                <p>Thank you for stopping to read a little bit about me.</p>
                <p>
                  Well, what can I tell you? My name is Cat. I am a cat and my
                  best friends are Moose and Goose. They take good care of me.
                  They know how important it is to look after a pet, and they do
                  it really well.
                </p>
                <p>
                  I can be often found following them on their adventures. You
                  might even see me in their stories... I’m never far away!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutScene;
