import React, { useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { listPosts, listResultsSelector } from "../../../src/store/posts";
import PostBlock from "./components/PostBlock";

interface IProps {
  listPosts: any;
  posts: any;
}

export const BlogScene = ({ listPosts, posts }: IProps) => {
  useEffect(() => {
    listPosts();
  }, [listPosts]);

  useEffect(() => {
    document.title = "Moose and Goose books | Blog";
  }, []);

  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Latest</h1>
          <h2 className="splash-subtitle">updates</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Row>
            {posts &&
              posts.map((post: any, i: number) => (
                <PostBlock key={i} {...post} />
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({ posts: listResultsSelector(state) });

export default connect(mapStateToProps, { listPosts })(BlogScene);
