import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import HttpsRedirect from "react-https-redirect";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import configureStore from "./store";

const store = configureStore();

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
