import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import _ from "lodash";
import classNames from "classnames/bind";
import goose from "../../assets/matching/goose.jpg";
import moose from "../../assets/matching/moose.jpg";
import cat from "../../assets/matching/cat.jpg";
import moon from "../../assets/matching/moon.jpg";
import earth from "../../assets/matching/earth.jpg";
import zome from "../../assets/matching/zome.jpg";
import rocket from "../../assets/matching/rocket.jpg";


import bauble from "../../assets/matching2/bauble.jpg";
import hat from "../../assets/matching2/hat.jpg";
import cane from "../../assets/matching2/cane.jpg";
import tree from "../../assets/matching2/tree.jpg";
import stocking from "../../assets/matching2/stocking.jpg";
import gingerbread from "../../assets/matching2/gingerbread.jpg";
import holly from "../../assets/matching2/holly.jpg";

import styles from "./matching-scene.module.scss";

const cx = classNames.bind(styles);

const tileImages: any = {
  goose: goose,
  moose: moose,
  cat: cat,
  moon: moon,
  earth: earth,
  zome: zome,
  rocket: rocket,
  bauble: bauble,
  hat: hat,
  cane: cane,
  tree: tree,
  stocking: stocking,
  gingerbread: gingerbread,
  holly: holly,
};

// const tilesValues: string[] = [
//   "earth",
//   "rocket",
//   "moose",
//   "zome",
//   "cat",
//   "goose",
//   "moon",
//   "saturn",
//   "asteroid",
// ];


const tilesValues: string[] = [
  "bauble",
  "hat",
  "cane",
  "tree",
  "stocking",
  "gingerbread",
  "holly",
  "saturn",
  "asteroid",
];

export const MatchingScene = ({ ...rest }: any) => {
  const [grid, setGrid] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedItem2, setSelectedItem2] = useState<any>(null);
  const [isDisabled, setDisabled] = useState<any>(false);
  const [matched, setMatched] = useState<number[]>([]);
  const [totalTiles] = useState<number>(6);
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = "Moose and Goose books | Matching Pairs";
    const tilesGrid = initialiseGrid(totalTiles);
    setGrid(tilesGrid);
  }, [totalTiles]);

  useEffect(() => {
    if (matched.length === totalTiles * 2) {
      setShow(true);
    }
  }, [matched, totalTiles]);

  const handlePlayAgain = () => {
    setShow(false);
    setMatched([]);
    setDisabled(false);
    setSelectedItem(null);
    setSelectedItem2(null);
    const tilesGrid = initialiseGrid(totalTiles);
    setGrid(tilesGrid);
  };

  const handleSelectItem = (item: any, index: number) => {
    if (!isDisabled) {
      if (selectedItem && selectedItem.id !== index) {
        setSelectedItem2(item);
        setDisabled(true);

        setTimeout(() => {
          if (selectedItem.label === item.label) {
            setMatched([...matched, selectedItem?.id, index]);
          }
          setSelectedItem(null);
          setSelectedItem2(null);
          setDisabled(false);
        }, 1000);
      } else {
        setSelectedItem(item);
      }
    }
  };

  const initialiseGrid = (tiles: number) => {
    let tilesArr: any[] = _.slice(tilesValues, 0, tiles);

    return _.shuffle(
      tilesArr.reduce((prev: string[], cur: string) => {
        return [...prev, cur, cur];
      }, [])
    ).map((item, index) => {
      return { label: item, id: index };
    });
  };

  return (
    <>
      <div className="splash-small level-0">
        <Container>
          <h1 className="splash-title">Christmas</h1>
          <h2 className="splash-subtitle">Match</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content page-content--tight">
        <Container>
          <Row>
            <Col sm={12} md={{ span: 8, offset: 2 }}>
              <div className={styles.GameSquare}>
                {show && (
                  <div className={styles.GridOverlay}>
                    <h2 className={styles.GridTitle}>Great job!</h2>
                    <h2 className={styles.GridSubTitle}>Christmas Space champion</h2>
                    <Button
                      variant="success"
                      size="lg"
                      onClick={handlePlayAgain}
                    >
                      Play Again
                    </Button>
                  </div>
                )}
                <div className={styles.Grid}>
                  {grid &&
                    grid.map((block: any, index: number) => (
                      <div key={index} className={styles.GridItem}>
                        <div
                          className={cx(styles.flipCard, {
                            [styles.flipCardActive]:
                              index === selectedItem?.id ||
                              index === selectedItem2?.id ||
                              matched.includes(index),
                            [styles.matched]: matched.includes(index),
                          })}
                          onClick={() => {
                            handleSelectItem(block, index);
                          }}
                        >
                          <div className={cx(styles.flipCardInner)}>
                            <div className={styles.flipCardFront}>1</div>
                            <div className={styles.flipCardBack}>
                              <img
                                src={tileImages[block.label]}
                                alt={block.label}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MatchingScene;
