import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const initialItems = [
  "Item 1",
  "Item 2",
  "Item 3",
  "Item 4",
  "Item 5",
  "Item 6",
  "Item 7",
  "Item 8",
  "Item 9",
  "Item 10",
];

// fake data generator

const insert = (arr: string[], index: number, newItem: string) => [
  ...arr.slice(0, index),

  newItem,

  ...arr.slice(index),
];

const grid = 8;

const getItemStyle = (draggableStyle: any, isDragging: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});

export const PhonicsScene = ({ ...rest }: any) => {
  const [items, setItems] = useState(initialItems);
  const [items2, setItems2] = useState<string[]>([]);
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    console.log(result);

    if (
      result.destination.droppableId === "droppable" &&
      result.source.droppableId === "droppable"
    ) {
      const fromAray = items.filter(
        (item, index) => index !== result.source.index
      );

      const destArray = insert(
        fromAray,
        result.destination.index,
        items[result.source.index]
      );

      setItems(destArray);
    }

    if (
      result.destination.droppableId === "droppable2" &&
      result.source.droppableId === "droppable2"
    ) {
      const fromAray = items2.filter(
        (item, index) => index !== result.source.index
      );

      const destArray = insert(
        fromAray,
        result.destination.index,
        items2[result.source.index]
      );

      setItems2(destArray);
    }

    if (
      result.destination.droppableId === "droppable2" &&
      result.source.droppableId === "droppable"
    ) {
      const fromAray = items.filter(
        (item, index) => index !== result.source.index
      );

      const destArray = insert(
        items2,
        result.destination.index,
        items[result.source.index]
      );

      setItems(fromAray);
      setItems2(destArray);
    }

    if (
      result.destination.droppableId === "droppable" &&
      result.source.droppableId === "droppable2"
    ) {
      const fromAray = items2.filter(
        (item, index) => index !== result.source.index
      );

      const destArray = insert(
        items,
        result.destination.index,
        items2[result.source.index]
      );
      setItems2(fromAray);
      setItems(destArray);
    }
  };
  return (
    <>
      <div className="splash-small level-0">
        <Container>
          <h1 className="splash-title">Phonics</h1>
          <h2 className="splash-subtitle">Fun</h2>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content page-content--tight">
        <Container>
          <DragDropContext onDragEnd={onDragEnd}>
            <Row>
              <Col>
                <Droppable droppableId="droppable">
                  {(provided: any, snapshot: any) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {items.map((item: any, index: number) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided: any, snapshot: any) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  snapshot.isDragging
                                )}
                              >
                                {item}
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
              <Col>
                <Droppable droppableId="droppable2">
                  {(provided: any, snapshot: any) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {items2.map((item: any, index: number) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided: any, snapshot: any) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  snapshot.isDragging
                                )}
                              >
                                {item}
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            </Row>
          </DragDropContext>
        </Container>
      </div>
    </>
  );
};

export default PhonicsScene;
