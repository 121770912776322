import { createSlice } from "@reduxjs/toolkit";
import { postsService as service } from "../../services";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export const initialState = {
  createError: null,
  createIsBusy: false,
  createResult: null,
  deleteError: null,
  deleteIsBusy: false,
  deleteResult: null,
  fetchError: null,
  fetchIsBusy: false,
  fetchResult: null,
  listError: null,
  listHasFilters: false,
  listIsBusy: false,
  listResult: null,
  patchError: null,
  patchIsBusy: false,
  patchResult: null,
};

// slice
export const postsSlice = createSlice({
  initialState,
  name: "posts",
  reducers: {
    createPostsBusy: (state, action) => {
      state.createIsBusy = action.payload;
    },
    createPostsError: (state, action) => {
      state.createError = action.payload;
      state.createResult = null;
    },
    createPostsSuccess: (state, action) => {
      state.createResult = action.payload;
      state.createError = null;
    },
    deletePostsBusy: (state, action) => {
      state.deleteIsBusy = action.payload;
    },
    deletePostsError: (state, action) => {
      state.deleteError = action.payload;
    },
    deletePostsSuccess: (state, action) => {
      state.deleteResult = action.payload;
    },
    fetchPostsBusy: (state, action) => {
      state.fetchIsBusy = action.payload;
    },
    fetchPostsError: (state, action) => {
      state.fetchError = action.payload;
    },
    fetchPostsSuccess: (state, action) => {
      state.fetchResult = action.payload;
    },
    listPostsBusy: (state, action) => {
      state.listIsBusy = action.payload;
    },
    listPostsError: (state, action) => {
      state.listError = action.payload;
    },
    listPostsFiltered: (state, action) => {
      state.listHasFilters = action.payload;
    },
    listPostsSuccess: (state, action) => {
      state.listResult = action.payload;
    },
    patchPostsBusy: (state, action) => {
      state.patchIsBusy = action.payload;
    },
    patchPostsError: (state, action) => {
      state.patchError = action.payload;
    },
    patchPostsSuccess: (state, action) => {
      state.patchResult = action.payload;
    },
    resetPosts: () => initialState,
  },
});

export const {
  createPostsBusy,
  createPostsError,
  createPostsSuccess,
  deletePostsBusy,
  deletePostsError,
  deletePostsSuccess,
  fetchPostsBusy,
  fetchPostsError,
  fetchPostsSuccess,
  listPostsFiltered,
  listPostsBusy,
  listPostsError,
  listPostsSuccess,
  patchPostsBusy,
  patchPostsError,
  patchPostsSuccess,
  resetPosts,
} = postsSlice.actions;

// thunks
export const getPost = (id) => (dispatch) => {
  dispatch(fetchPostsBusy(true));

  return service
    .getPost(id)
    .then((item) => {
      dispatch(
        fetchPostsSuccess({
          postId: item.sys.id,
          createdAt: item.sys.createdAt,
          headline: item.fields.headline,
          blurb: documentToReactComponents(item.fields.blurb),
          content: documentToReactComponents(item.fields.content),
          banner: item.fields.banner.fields.file.url,
        })
      );

      dispatch(fetchPostsBusy(false));
    })
    .catch((error) => {
      dispatch(fetchPostsError(true, error));
      dispatch(fetchPostsBusy(false));
    });
};

export const listPosts = () => (dispatch) => {
  dispatch(listPostsBusy(true));
  dispatch(listPostsFiltered(false));

  return service
    .getPosts()
    .then((response) => {
      try {
        dispatch(
          listPostsSuccess({
            results: response.items.map((item) => {
              return {
                postId: item.sys.id,
                createdAt: item.sys.createdAt,
                headline: item.fields.headline,
                blurb: documentToReactComponents(item.fields.blurb),
                content: documentToReactComponents(item.fields.content),
                banner: item.fields.banner?.fields.file.url || null,
              };
            }),
          })
        );
      } catch (e) {
        console.log(e);
      }
      dispatch(listPostsBusy(false));
    })
    .catch((error) => {
      dispatch(listPostsError(true, error));
      dispatch(listPostsBusy(false));
    });
};

// selectors
export const createIsBusySelector = (state) => state.posts.createIsBusy;
export const createErrorSelector = (state) => state.posts.createError;
export const createHasErrorSelector = (state) =>
  state.posts.createError ? true : false;
export const createResultSelector = (state) => state.posts.createResult;

export const deleteIsBusySelector = (state) => state.posts.deleteIsBusy;
export const deleteResultSelector = (state) => state.posts.deleteResult;
export const deleteErrorSelector = (state) => state.posts.deleteError;
export const deleteHasErrorSelector = (state) =>
  state.posts.deleteError ? true : false;

export const fetchIsBusySelector = (state) => state.posts.fetchIsBusy;
export const fetchResultSelector = (state) => state.posts.fetchResult;
export const fetchErrorSelector = (state) => state.posts.fetchError;
export const fetchHasErrorSelector = (state) =>
  state.posts.fetchError ? true : false;

export const listHasResultSelector = (state) =>
  state.posts.listResult ? state.posts.listResult.count > 0 : false;
export const listIsBusySelector = (state) => state.posts.listIsBusy;
export const listResultSelector = (state) => state.posts.listResult;
export const listResultsSelector = (state) =>
  state.posts.listResult ? state.posts.listResult.results : null;
export const listResultCountSelector = (state) =>
  state.posts.listResult ? state.posts.listResult.count : 0;
export const listErrorSelector = (state) => state.posts.listError;
export const listHasErrorSelector = (state) =>
  state.posts.listError ? true : false;
export const listHasFiltersSelector = (state) => state.posts.listHasFilters;

export const patchIsBusySelector = (state) => state.posts.patchIsBusy;
export const patchResultSelector = (state) => state.posts.patchResult;
export const patchErrorSelector = (state) => state.posts.patchError;
export const patchHasErrorSelector = (state) =>
  state.posts.patchError ? true : false;

export default postsSlice.reducer;
