import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import amazonuk from "../../../../assets/stockists/amazonuklogo.png";
import barnesnoble from "../../../../assets/stockists/barnesnoble.png";
import amazonaus from "../../../../assets/stockists/amazonaus.png";
import blackwells from "../../../../assets/stockists/blackwells.png";
import adlibris from "../../../../assets/stockists/adlibris.png";
import bookdepository from "../../../../assets/stockists/bookdepository.png";
import gardners from "../../../../assets/stockists/gardners.png";
import waterstones from "../../../../assets/stockists/waterstones.png";
import browns from "../../../../assets/stockists/browns.png";
import booktopia from "../../../../assets/stockists/booktopia.png";
import sundog from "../../../../assets/stockists/sundog.png";
import bookstall from "../../../../assets/stockists/bookstall.png";
import tanum from "../../../../assets/stockists/tanum.png";
import bokus from "../../../../assets/stockists/bokus.png";
import funphonics from "../../../../assets/stockists/funphonics.png";
import whsmiths from "../../../../assets/stockists/whsmiths.png";
import abebooks from "../../../../assets/stockists/abebooks.png";
import dymocks from "../../../../assets/stockists/dymocks.png";
import stillnorth from "../../../../assets/stockists/stillnorth.png";
import thoughtful from "../../../../assets/stockists/thoughtful.png";
import subt from "../../../../assets/stockists/subt.png";
import amazon from "../../../../assets/stockists/amazon.png";
import amazonfr from "../../../../assets/stockists/amazonfr.png";
import bookrack from "../../../../assets/stockists/bookrack.png";
import bol from "../../../../assets/stockists/bol.png";
import foyles from "../../../../assets/stockists/foyles.png";
import bokklubben from "../../../../assets/stockists/bokklubben.png";
import mightyape from "../../../../assets/stockists/mightyape.png";
import saxo from "../../../../assets/stockists/saxo.png";
import dollys from "../../../../assets/stockists/dollys.png";
import walmart from "../../../../assets/stockists/walmart.png";

export const Stockists = () => {
  return (
    <Container className="pt-5">
      <Row className="text-center">
        <Col>
          <h2>UK Stockists:</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.amazon.co.uk/Moose-Goose-Moon-Craig-Westmoreland/dp/1913319768/ref=sr_1_1?crid=4PV18UNTT82B&dchild=1&keywords=moose+and+goose+go+to+the+moon&qid=1613240653&sprefix=moose+and+%2Caps%2C157&sr=8-1"
          >
            <img src={amazonuk} className="img-fluid" alt="Amazon.co.uk" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.waterstones.com/book/moose-and-goose-go-to-the-moon/craig-westmoreland/mark-j-cullen/9781913319762"
          >
            <img src={waterstones} className="img-fluid" alt="Waterstones" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.foyles.co.uk/witem/childrens/moose-goose-go-to-the-moon,craig-westmoreland-mark-j-cullen-9781913319762"
          >
            <img src={foyles} className="img-fluid" alt="Foyles" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.whsmith.co.uk/products/moose-and-goose-go-to-the-moon/craig-westmoreland/mark-j-cullen/paperback/9781913319762.html"
          >
            <img src={whsmiths} className="img-fluid" alt="whsmiths" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.gardners.com/Search/KeywordAnonymous#"
          >
            <img src={gardners} className="img-fluid" alt="Gardners Books" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blackwells.co.uk/bookshop/product/Moose--Goose-go-to-the-Moon-by-Westmoreland-Craig/9781913319762"
          >
            <img src={blackwells} className="img-fluid" alt="Blackwells" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bookdepository.com/Moose--Goose-go-the-Moon-Craig-Westmoreland-Mark-J-Cullen/9781913319762"
          >
            <img
              src={bookdepository}
              className="img-fluid"
              alt="bookdepository"
            />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.brownsbfs.co.uk/Product/Westmoreland-Craig/Moose--Goose-go-to-the-Moon/9781913319762"
          >
            <img src={browns} className="img-fluid" alt="Browns Books" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.funphonics.education/product/moose-and-goose-go-to-the-moon/"
          >
            <img src={funphonics} className="img-fluid" alt="fun phonics" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.abebooks.co.uk/9781913319762/Moose-Goose-go-Moon-Westmoreland-1913319768/plp"
          >
            <img src={abebooks} className="img-fluid" alt="abebooks.co.uk" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.thethoughtfulspotbooks.com/"
          >
            <img src={thoughtful} className="img-fluid" alt="thoughtful" />
          </a>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <h2 className="mt-3">European Stockists:</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bol.com/nl/s/?searchtext=moose+and+goose+go+to+the+moon&searchContext=media_all&appliedSearchContextId=&suggestFragment=&adjustedSection=&originalSection=&originalSearchContext=&section=main&N=0&defaultSearchContext=media_all"
          >
            <img src={bol} className="img-fluid" alt="bol.com" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.amazon.fr/Moose-Goose-Moon-Craig-Westmoreland/dp/1913319768"
          >
            <img src={amazonfr} className="img-fluid" alt="amazon.fr" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.adlibris.com/storkund/search?phrase=moose%20and%20goose%20go%20to%20the%20moon&sort=relevance&sortOrder=desc"
          >
            <img src={adlibris} className="img-fluid" alt="adlibris" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tanum.no/_moose--goose-go-to-the-moon-craig-westmoreland-9781913319762"
          >
            <img src={tanum} className="img-fluid" alt="Tanum" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bokus.com/bok/9781913319762/moose-goose-go-to-the-moon/"
          >
            <img src={bokus} className="img-fluid" alt="Bokus" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bokklubben.no/skjoennlitteratur-for-barn-og-ungdom-interaktive-spenningshistorier/moose-goose-go-to-the-moon-craig-westmoreland/produkt.do?produktId=25725991"
          >
            <img src={bokklubben} className="img-fluid" alt="bokklubben" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.saxo.com/dk/moose-goose-go-to-the-moon_paperback_9781913319762"
          >
            <img src={saxo} className="img-fluid" alt="saxo" />
          </a>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <h2 className="mt-3">USA &amp; Canada Stockists:</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.amazon.com/Moose-Goose-Moon-Craig-Westmoreland/dp/1913319768/ref=sr_1_1?crid=2ZX4HD4E8FEQW&dchild=1&keywords=moose+and+goose+go+to+the+moon&qid=1615540541&sprefix=moose+and+goo%2Caps%2C227&sr=8-1"
          >
            <img src={amazon} className="img-fluid" alt="Amazon.com" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.barnesandnoble.com/w/moose-goose-go-to-the-moon-craig-westmoreland/1138800775?ean=9781913319762"
          >
            <img src={barnesnoble} className="img-fluid" alt="Barnes & Noble" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.walmart.com/ip/Moose-Goose-go-to-the-Moon-Paperback-9781913319762/950029804"
          >
            <img src={walmart} className="img-fluid" alt="Walmart.com" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.sundogbooks.com/book/9781913319762"
          >
            <img src={sundog} className="img-fluid" alt="Sundog Books" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stillnorthbooks.com/book/9781913319762"
          >
            <img
              src={stillnorth}
              className="img-fluid"
              alt="still north books & bar"
            />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://store.subbooks.com/book/9781913319762"
          >
            <img src={subt} className="img-fluid" alt="subterranean books" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.thebookstall.com/book/9781913319762"
          >
            <img src={bookstall} className="img-fluid" alt="The Bookstall" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bookrackwa.com/book/9781913319762"
          >
            <img src={bookrack} className="img-fluid" alt="The Book Rack" />
          </a>
        </Col>

        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.dollysbookstore.com/book/9781913319762"
          >
            <img src={dollys} className="img-fluid" alt="Dollys" />
          </a>
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <h2 className="mt-3">Australian Stockists:</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.amazon.com.au/Moose-Goose-Moon-Craig-Westmoreland/dp/1913319768/ref=sr_1_1?dchild=1&keywords=moose+and+goose+go+to+the+moon&qid=1613236960&sr=8-1"
          >
            <img src={amazonaus} className="img-fluid" alt="Amazon.com.au" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.dymocks.com.au/book/moose-and-goose-go-to-the-moon-by-craig-westmoreland-and-mark-j-cullen-9781913319762"
          >
            <img src={dymocks} className="img-fluid" alt="dymocks" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.booktopia.com.au/moose-goose-go-to-the-moon-craig-westmoreland/book/9781913319762.html"
          >
            <img src={booktopia} className="img-fluid" alt="booktopia" />
          </a>
        </Col>
        <Col xs={6} sm={4} md={3} lg={3}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.mightyape.co.nz/product/moose-goose-go-to-the-moon/34667414"
          >
            <img src={mightyape} className="img-fluid" alt="mightyape" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Stockists;
