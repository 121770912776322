import React from "react";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GA from "../utils/GoogleAnalytics";
import logo from "../assets/base/logo.svg";

interface IProps {
  items: any;
  total: number;
}

export const Header = ({ items, total }: IProps) => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="transparent"
      className="mt-4 mb-4"
      variant={"dark"}
    >
      <Navbar.Brand as={Link} to="/">
        <img
          alt="Moose &amp; Goose"
          src={logo}
          height="59"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link eventKey="1" as={Link} to="/about">
            Characters
          </Nav.Link>
          <Nav.Link eventKey="2" as={Link} to="/shop">
            Shop
          </Nav.Link>
          <Nav.Link eventKey="4" as={Link} to="/stockists">
            Stockists
          </Nav.Link>
          <Nav.Link eventKey="3" as={Link} to="/funstuff">
            Fun stuff
          </Nav.Link>
          <Nav.Link eventKey="5" as={Link} to="/blog">
            Blog
          </Nav.Link>

          {/* <Nav.Link eventKey="4" as={Link} to="/resources">
            Downloads
          </Nav.Link>
          <Nav.Link eventKey="5" as={Link} to="/colouring">
            Colouring
          </Nav.Link>
          <Nav.Link eventKey="6" as={Link} to="/matching">
            Game
          </Nav.Link> */}
        </Nav>

        <Nav>
          {total > 0 && (
            <Nav.Link className="pt-1 pb-1" as={Link} to="/basket">
              <h5 className="mb-0">
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-email`}>{total} items</Tooltip>
                  }
                >
                  <span>
                    <i
                      className="fa fa-shopping-cart mr-1"
                      aria-hidden="true"
                    ></i>
                    <Badge pill variant="danger">
                      {total}
                    </Badge>
                  </span>
                </OverlayTrigger>
              </h5>
            </Nav.Link>
          )}
          <Nav.Link
            href="mailto: contact@mooseandgoosebooks.co.uk"
            className="d-none d-lg-block"
            onClick={() => {
              GA.trackEvent("header", "social", "Email", "Email");
            }}
          >
            <OverlayTrigger
              key={"bottom"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-email`}>E-mail us</Tooltip>}
            >
              <i
                className="far fa-envelope fa-lg"
                aria-label="E-mail moose and goose"
              ></i>
            </OverlayTrigger>
          </Nav.Link>
          <Nav.Link
            eventKey={2}
            href="https://www.instagram.com/mooseandgoosebooks/"
            target="_blank"
            className="d-none d-lg-block"
            onClick={() => {
              GA.trackEvent("header", "social", "Instagram", "Instagram");
            }}
          >
            <OverlayTrigger
              key={"bottom"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-instagram`}>Instagram</Tooltip>}
            >
              <i className="fab fa-instagram fa-lg"></i>
            </OverlayTrigger>
          </Nav.Link>
          <Nav.Link
            eventKey={3}
            href="https://twitter.com/moosegoosebooks"
            target="_blank"
            className="d-none d-lg-block"
            onClick={() => {
              GA.trackEvent("header", "social", "twitter", "twitter");
            }}
          >
            <OverlayTrigger
              key={"bottom"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-twitter`}>Twitter</Tooltip>}
            >
              <i className="fab fa-twitter fa-lg"></i>
            </OverlayTrigger>
          </Nav.Link>
          <Nav.Link
            eventKey={4}
            href="https://www.facebook.com/mooseandgoosebooks"
            target="_blank"
            className="d-none d-lg-block"
            onClick={() => {
              GA.trackEvent("header", "social", "facebook", "facebook");
            }}
          >
            <OverlayTrigger
              key={"bottom"}
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-facebook`}>Facebook</Tooltip>}
            >
              <i className="fab fa-facebook fa-lg"></i>
            </OverlayTrigger>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
