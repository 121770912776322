import React, { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";

declare const window: any;

const GoogleAnalytics = ({ options }: any) => {
  let location = useLocation();
  const [lastLocation, setLastLocation] = useState<null | string>(null);
  useEffect(() => {
    const page = location.pathname + location.search;

    const {
      location: { origin },
    } = window;
    if (lastLocation !== page && process.env.REACT_APP_GA_TRACKING_ID) {
      if (process.env.REACT_APP_GA_DEBUG) {
        console.log(origin, page, document.title);
      }
      window.gtag("event", "page_view", {
        page_title: document.title,
        page_location: origin,
        page_path: page,
        send_to: process.env.REACT_APP_GA_TRACKING_ID,
      });
      setLastLocation(page);
    }
  }, [lastLocation, location, options]);

  return null;
};

const RouteTracker = () => (
  <Route path="">
    <GoogleAnalytics />
  </Route>
);

const trackEvent = (
  action: string,
  category: string,
  label: string,
  value: string
) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

const init = (options = {}) => {
  return process.env.REACT_APP_GA_TRACKING_ID;
};

const AnalyticsModule = {
  GoogleAnalytics,
  RouteTracker,
  trackEvent,
  init,
};

export default AnalyticsModule;
