import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Formik, Field } from "formik";

import {
  completeBasket,
  getBasketItems,
  itemsSelector,
  removeBasketItem,
  totalSelector,
  totalWeightSelector,
  updateBasketItems,
} from "../../store/basket";

declare global {
  interface Window {
    paypal: any;
  }
}

interface IColour {
  code: string;
  hex: string;
  title: string;
  img?: string;
}

interface IType {
  code: string;
  title: string;
}

interface ISize {
  code: string;
  title: string;
}

interface IItem {
  colour: IColour;
  displayPrice: number;
  img: string;
  product: string;
  productId: string | number;
  qty: number;
  sku: string;
  size?: ISize;
  total: number;
  type?: IType;
}

interface IProps {
  completeBasket: (details: any) => void;
  getBasketItems: () => void;
  items: IItem[];
  removeBasketItem: (props: string) => void;
  total: number;
  updateBasketItems: (items: any[]) => void;
  weight: number;
}

const SHIPPING_BANDED_PRICES: any = {
  500: {
    STD: 2.99,
    EXP: 13.0,
  },
  750: {
    STD: 3.99,
    EXP: 13.0,
  },
  1000: {
    STD: 4.99,
    EXP: 13.0,
  },
  2000: {
    STD: 6.99,
    EXP: 13.0,
  },
  10000: {
    STD: 15.99,
    EXP: 15.99,
  },
};

export const BasketScene = ({
  completeBasket,
  getBasketItems,
  items,
  removeBasketItem,
  total,
  updateBasketItems,
  weight,
}: IProps) => {
  const [shippingPrices, setShippingPrices] = useState<any>(null);
  const [shippingPrice, setShippingPrice] = useState<any>(null);
  const [shippingType, setShippingType] = useState<any>("STD");
  let history = useHistory();
  const buttonRef = useRef(null);

  useEffect(() => {
    document.title = "Moose and Goose books | Basket";
  }, []);

  useEffect(() => {
    getBasketItems();
  }, [getBasketItems]);

  useEffect(() => {
    const activeWeightBands = Object.keys(SHIPPING_BANDED_PRICES).filter(
      (band) => parseInt(band) > weight
    );
    const weightBand = activeWeightBands.length ? activeWeightBands[0] : 10000;
    const activeShippingPrices = SHIPPING_BANDED_PRICES[weightBand];

    setShippingPrices(activeShippingPrices);
    setShippingPrice(activeShippingPrices[shippingType]);
  }, [weight, shippingPrice, shippingType]);

  const handleFormikSubmit = (values: any) => {
    updateBasketItems(values);
  };

  const handleFormikChange = (values: any) => {
    updateBasketItems(values);
    setShippingType(values.shipping);
  };

  const initialValues =
    Object.values(items).length > 0
      ? Object.values(items).reduce((prev, item) => {
          const newNode: any = {};
          newNode[item.sku] = item.qty;
          return { ...prev, ...newNode };
        }, {})
      : null;

  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Basket</h1>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/shop">
              Shop
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Basket</Breadcrumb.Item>
          </Breadcrumb>
          {!initialValues && (
            <Alert variant="info">
              <Alert.Heading>Basket empty</Alert.Heading>
              <p>There are currently no items in your basket</p>
              <Button as={Link} to="/shop" variant="info">
                Continue shopping
              </Button>
            </Alert>
          )}
          {initialValues && (
            <Formik
              novalidate
              onSubmit={handleFormikSubmit}
              validate={handleFormikChange}
              validateOnChange
              initialValues={{ ...initialValues, shipping: "STD" }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Card className="shopping-cart">
                    <Card.Header>
                      <h4>
                        <i className="fa fa-shopping-basket"></i> Items
                      </h4>
                    </Card.Header>
                    <Card.Body>
                      {items &&
                        Object.values(items).map((item, i) => (
                          <Fragment key={`productrow-${i}`}>
                            <Row>
                              <Col md={2} className="text-center">
                                {item && item.colour && item.colour.img && (
                                  <img
                                    className="img-responsive"
                                    src={item.colour.img}
                                    alt="prewiew"
                                    width="120"
                                  />
                                )}
                                {item && !item.colour && (
                                  <img
                                    className="img-responsive"
                                    src={item.img}
                                    alt="prewiew"
                                    width="120"
                                  />
                                )}
                              </Col>
                              <Col
                                md={6}
                                className="text-sm-center text-md-left"
                              >
                                <Link
                                  className="product-name"
                                  to={`/shop/${item.productId}`}
                                >
                                  <h4>
                                    <strong>{item.product}</strong>
                                  </h4>
                                </Link>
                                {item.colour && (
                                  <p className="mb-0">{item.colour.title}</p>
                                )}
                                {item.size && (
                                  <p className="mb-0">{item.size.title}</p>
                                )}
                                {item.type && (
                                  <p className="mb-0"> {item.type.title} </p>
                                )}
                              </Col>
                              <Col
                                md={4}
                                className="text-sm-center text-md-right"
                              >
                                <Row>
                                  <Col
                                    xs={3}
                                    md={6}
                                    className="text-md-right text-center"
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <h6>
                                      <strong>
                                        &pound;{item.displayPrice}{" "}
                                        <span className="text-muted">x</span>
                                      </strong>
                                    </h6>
                                  </Col>
                                  <Col xs={4}>
                                    <div className="quantity">
                                      <Field name={item.sku}>
                                        {({ field }: any) => (
                                          <Fragment>
                                            <Form.Label className="sr-only">
                                              Qty
                                            </Form.Label>
                                            <Form.Control
                                              id={item.sku}
                                              className="qty"
                                              value={field.value}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              type="number"
                                              step="1"
                                              max="99"
                                              min="1"
                                              name={item.sku}
                                              title="Qty"
                                              onInput={(event: any) => {
                                                if (event.target.value > 99) {
                                                  event.target.value = 99;
                                                }

                                                if (event.target.value < 1) {
                                                  event.target.value = 1;
                                                }
                                              }}
                                            />
                                          </Fragment>
                                        )}
                                      </Field>
                                    </div>
                                  </Col>
                                  <Col xs={2} className="text-right">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger btn-xs"
                                      onClick={() => {
                                        removeBasketItem(item.sku);
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </Fragment>
                        ))}
                    </Card.Body>
                    <Card.Footer>
                      <h4>
                        <i className="fa fa-truck"></i> Delivery
                      </h4>
                    </Card.Footer>
                    {shippingPrices && shippingPrice && (
                      <>
                        <Card.Body>
                          <Row>
                            <Col></Col>
                            <Col className="text-right">
                              <Form.Group controlId="shipping">
                                {touched.shipping && errors.shipping ? (
                                  <div className="error-message">
                                    {errors.shipping}
                                  </div>
                                ) : null}
                                <Form.Check
                                  checked={values.shipping === "STD"}
                                  isInvalid={
                                    touched.shipping && !!errors.shipping
                                  }
                                  custom
                                  bsCustomPrefix={"custom-shipping"}
                                  name="shipping"
                                  type={"radio"}
                                  id={"STD"}
                                  value={"STD"}
                                  label={`UK Standard Delivery (£${shippingPrices.STD})`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Form.Check
                                  checked={values.shipping === "EXP"}
                                  isInvalid={
                                    touched.shipping && !!errors.shipping
                                  }
                                  custom
                                  bsCustomPrefix={"custom-shipping"}
                                  name="shipping"
                                  type={"radio"}
                                  id={"EXP"}
                                  value={"EXP"}
                                  label={`UK Express 48hr (£${shippingPrices.EXP})`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <div className="text-right">
                            <div
                              style={{ paddingTop: "5px", marginRight: "10px" }}
                            >
                              Product price: <b>&pound;{total.toFixed(2)}</b>
                            </div>
                            <div
                              style={{ paddingTop: "5px", marginRight: "10px" }}
                            >
                              Delivery: <b>&pound;{shippingPrice.toFixed(2)}</b>
                            </div>
                            <div
                              style={{
                                paddingTop: "5px",
                                marginRight: "10px",
                                fontSize: "28px",
                              }}
                            >
                              Total:{" "}
                              <b>&pound;{(total + shippingPrice).toFixed(2)}</b>
                            </div>
                          </div>
                        </Card.Footer>
                        <Card.Footer>
                          <Row>
                            <Col
                              md={{ span: 6, offset: 6 }}
                              lg={{ span: 5, offset: 7 }}
                            >
                              <PayPalButton
                                amount="0.01"
                                options={{
                                  commit: true,
                                }}
                                ref={buttonRef}
                                createOrder={(data: any, actions: any) => {
                                  return actions.order.create({
                                    purchase_units: [
                                      {
                                        amount: {
                                          value: (
                                            total + shippingPrice
                                          ).toFixed(2),
                                          breakdown: {
                                            item_total: {
                                              currency_code: "GBP",
                                              value: total.toFixed(2),
                                            },
                                            shipping: {
                                              currency_code: "GBP",
                                              value: shippingPrice.toFixed(2),
                                            },
                                          },
                                        },
                                        description: "Moose & Goose Order",
                                        items: Object.values(items).map(
                                          (item) => {
                                            return {
                                              name: item.product,
                                              unit_amount: {
                                                currency_code: "GBP",
                                                value: item.displayPrice.toFixed(
                                                  2
                                                ),
                                              },
                                              quantity: item.qty,
                                              sku: item.sku,
                                            };
                                          }
                                        ),
                                      },
                                    ],
                                  });
                                }}
                                onApprove={(data: any, actions: any) => {
                                  return actions.order
                                    .capture()
                                    .then((details: any) => {
                                      completeBasket(details);
                                      history.push("/confirm");
                                    });
                                }}
                              />
                            </Col>
                          </Row>
                        </Card.Footer>
                      </>
                    )}
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  items: itemsSelector(state),
  total: totalSelector(state),
  weight: totalWeightSelector(state),
});

export default connect(mapStateToProps, {
  completeBasket,
  getBasketItems,
  removeBasketItem,
  updateBasketItems,
})(BasketScene);
