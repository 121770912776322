// import axios from "axios";
import { createClient } from "contentful";

const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "4otupuj4yoin",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "dU-oT0eznnjzxGytGdFy0_Comh2Z8eKJfLCpGXTjM4c",
});

/*
getPost

Get a post

*/
export const getPost = (id) => {
  return client.getEntry(id);
};

/*
getPosts

Get list of posts

*/
export const getPosts = () => {
  return client.getEntries({ content_type: "blogPost" });
};

const postService = { getPost, getPosts };

export default postService;
