import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import postsSlice from "./posts";
import basketSlice from "./basket";

const createRootReducer = (history) =>
  combineReducers({
    basket: basketSlice,
    posts: postsSlice,
    router: connectRouter(history),
  });

const historyConfig = { basename: "/" };
export const history = createBrowserHistory(historyConfig);

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  );

  return store;
}
