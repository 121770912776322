import React from "react";
import { IColour } from "../../../../data";
import { Link } from "react-router-dom";
import GA from "../../../../utils/GoogleAnalytics";
interface IProps {
  img1: string;
  img2: string;
  displayPrice: number;
  productId: string | number;
  title: string;
  variations?: any;
}

export const ProductBlock = ({
  displayPrice,
  img1,
  img2,
  productId,
  title,
  variations,
}: IProps) => {
  return (
    <div className="col-md-4 col-sm-6">
      <div className="product-grid2">
        <div className="product-image2">
          <Link
            to={`/shop/${productId}`}
            onClick={() => {
              GA.trackEvent(
                "shopproduct",
                "buttons",
                "image",
                productId.toString()
              );
            }}
          >
            <img alt="Product 1" className="pic-1" src={img1} />
            <img alt="Product 2" className="pic-2" src={img2} />
          </Link>
          <ul className="social">
            <li>
              <Link to={`/shop/${productId}`} data-tip="View">
                <i className="fa fa-eye"></i>
              </Link>
            </li>
          </ul>
          <Link
            to={`/shop/${productId}`}
            className="add-to-cart"
            onClick={() => {
              GA.trackEvent(
                "shopproduct",
                "buttons",
                "View product",
                productId.toString()
              );
            }}
          >
            View product
          </Link>
        </div>
        <div className="product-content">
          <h3 className="title">
            <Link to={`/shop/${productId}`}>{title}</Link>
          </h3>

          <span className="price">&pound;{displayPrice}</span>
          {variations.colours && (
            <>
              <div className="mini-colour-summary">
                Available in {variations.colours.length} colours
              </div>
              <ul className="mini-colour-list">
                {variations.colours.map((colour: IColour) => (
                  <li
                    className="mini-colour"
                    style={{ backgroundColor: colour.hex }}
                    title={colour.title}
                  >
                    {colour.title}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductBlock;
