import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

interface IProps {
  orderId: string;
}

export const CheckoutScene = ({ orderId }: IProps) => {
  useEffect(() => {
    document.title = "Moose and Goose books | Checkout";
  }, []);
  return (
    <>
      <div className="splash-small  level-0">
        <Container>
          <h1 className="splash-title">Thank you!</h1>
        </Container>
        <div className="splash-bottom"></div>
      </div>
      <div className="page-content">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={"span"} as={Link} to="/shop">
              Shop
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Order Complete</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="mb-3">Your order has been placed!</h1>
          <p>
            Your order has been received and will be on its way as soon as
            possible!
          </p>
          <Button as={Link} to="/" variant="success">
            Return to homepage
          </Button>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  orderId: state.basket?.details?.id || null,
});

export default connect(mapStateToProps, {})(CheckoutScene);
