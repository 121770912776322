import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import nlbr from "react-nl2br";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { getPost, fetchResultSelector } from "../../../store/posts";

interface IProps {
  getPost: any;
  match: any;
  post: any;
}

export const BlogPostScene = ({ getPost, match, post }: IProps) => {
  useEffect(() => {
    getPost(match.params.postId);
  }, [getPost, match]);

  useEffect(() => {
    document.title = "Moose and Goose books | Blog Post";
  }, []);

  return (
    <>
      <div className="splash-minimal level-0"></div>
      <div className="page-content">
        <Container>
          {post && (
            <div>
              <Row>
                <Col>
                  <h1 className="mb-4">{post.headline}</h1>
                </Col>
              </Row>
              <Row>
                <Col>{nlbr(post.content)}</Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({ post: fetchResultSelector(state) });

export default connect(mapStateToProps, { getPost })(BlogPostScene);
